import React, { useState, useEffect } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import Button from "../../components/common/button";
import Loader from "../../components/common/loader/loader";
import useThankYouLogic from "./hooks/useThankYou";
import ShareComponent from "../../components/shareModal/shareModal";
import VolumeMute from "./../../assets/images/Mute.svg";
import VolumeFull from "./../../assets/images/volume-full.svg";
import useColinkExist from "../../services/hooks/useColinkExist";
import FollowingCheck from "../../assets/images/following-check.svg";
import {
  formatUrlWithProtocol,
  generateCloudinaryImageUrl,
  generateCloudinaryVideoThumbnail,
  generateCloudinaryVideoUrl,
  generateProfileImageUrl,
  getLocalStorage
} from "../../utils/common-function";
import mixpanel from "../../services/mixpanelService";
import WaitlistModal from "../../components/waitlistModal";
import clsx from "clsx";
import { envVariables } from "../../common/constants";

const ThankYou: React.FC = () => {
  const {
    videoRef,
    stashlink,
    navigateToColink,
    navigateToStashAgain,
    setShowParentVideo,
    parentVideoRef,
    userData,
    campaign,
    showParentVideo,
    parentVideoProgress,
    videoProgress,
    muted,
    setMuted,
    isShareModalOpen,
    setIsShareModalOpen
  } = useThankYouLogic();
  const { colinkExistData } = useColinkExist(stashlink?.id || "");
  const [isLoading, setIsLoading] = useState(true);
  const [isColinkVideoLoading, setIsColinkVideoLoading] = useState(true);
  const [isWaitlistModalOpen, setWaitlistModalOpen] = useState<boolean>(false);
  const [dynamicImageSize, setDynamicImageSize] = useState({ width: "", height: "" });

  const onboardingFeatureFlag = useFeatureIsOn("onboarding-flow");

  const userId = getLocalStorage("userId") as string;
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const isUserJoinedWaitlist = getLocalStorage("isUserJoinedWaitlist");

  const colinkVideoUrl = stashlink.thankYouMessageVideo?.replace(".mkv", ".mp4") ?? "";
  const parentVideoUrl = stashlink.parentStashlink?.thankYouMessageVideo?.replace(".mkv", ".mp4") ?? "";
  const colinkVideoThumbnail =
    stashlink.thankYouMessageVideo && generateCloudinaryVideoThumbnail(stashlink.thankYouMessageVideo, "750", "930", 1); // Example for generating thumbnail
  const parentVideoThumbnail =
    stashlink.parentStashlink?.thankYouMessageVideo &&
    generateCloudinaryVideoThumbnail(stashlink.parentStashlink?.thankYouMessageVideo, "750", "930", 1);
  const shareUrl = `${envVariables.domainUrl}/stashlink/${stashlink.id}`;

  const closeWaitListModal = () => {
    setWaitlistModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      if (windowHeight < 720) {
        setDynamicImageSize({ width: "560", height: "560" });
      } else {
        setDynamicImageSize({ width: "800", height: "1200" });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return !stashlink ? (
    <div className="h-[calc(100vh-48px)] w-screen flex justify-center items-center bg-soft-black">
      <Loader />
    </div>
  ) : (
    <div className="relative h-[calc(100vh-96px)] w-screen flex flex-col items-center pt-7 overflow-hidden">
      <div className={`absolute top-2 w-[calc(100vw-32px)] ${stashlink.parentStashlink && "grid grid-cols-2 gap-2"}`}>
        {!showParentVideo && stashlink.thankYouMessageVideo && (
          <div onClick={() => setShowParentVideo(false)} className="relative bg-grey w-full h-0.5 rounded-xl overflow-hidden z-10">
            <div className="bg-base-white absolute left-0 h-0.5" style={{ width: `${videoProgress}%` }}></div>
          </div>
        )}
        {stashlink.parentStashlink && stashlink.parentStashlink?.thankYouMessageVideo && (
          <div onClick={() => setShowParentVideo(true)} className="relative bg-grey w-full h-0.5 rounded-xl overflow-hidden z-10">
            <div className="bg-base-white absolute left-0 h-0.5" style={{ width: `${parentVideoProgress}%` }}></div>
          </div>
        )}
      </div>

      <h1 className="text-primary-l1 font-primary text-2xl z-10 [text-shadow:_2px_2px_0_rgb(0_0_0_/_60%)]">Thank You!</h1>

      <div className="absolute top-7 left-4 w-9 h-c-38 bg-primary-l1 rounded-full z-10">
        <img
          src={
            showParentVideo
              ? generateProfileImageUrl(stashlink.parentStashlink?.user.profilePhoto || "", "65", "65")
              : generateProfileImageUrl(stashlink.user.profilePhoto || "", "65", "65")
          }
          alt={showParentVideo ? stashlink.parentStashlink?.user.fullName : stashlink.user.fullName}
          className="w-33 h-33 absolute left-2/4 -translate-x-2/4 bottom-px rounded-full object-cover"
        />
      </div>

      {((!showParentVideo && stashlink.thankYouMessageVideo) || (showParentVideo && stashlink.parentStashlink?.thankYouMessageVideo)) && (
        <div className="absolute top-8 right-4 bg-transparent/10 rounded-full z-10 h-8 w-8 flex items-center justify-center backdrop-filter-blur">
          <img
            src={muted ? VolumeMute : VolumeFull}
            alt={VolumeFull}
            onClick={(event) => {
              mixpanel.track("sound-clicked", {
                location: "thank-you-page",
                muted: !muted,
                campaignName: campaign?.title,
                stashlinkId: stashlink?.id
              });
              event.stopPropagation();
              setMuted(!muted);
            }}
          />
        </div>
      )}

      {!showParentVideo &&
        (stashlink.thankYouMessageVideo ? (
          <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center z-10">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            )}
            <video
              muted={muted}
              ref={videoRef}
              src={generateCloudinaryVideoUrl(colinkVideoUrl, "750", "930")}
              className={`object-cover w-full h-full ${isLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
              autoPlay
              poster={colinkVideoThumbnail}
              loop={stashlink.parentStashlink?.thankYouMessageVideo ? false : true}
              playsInline
              onLoadedData={() => setIsLoading(false)}
            />
            <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-soft-black/0 from-30% via-soft-black/0 via-90% to-soft-black/60 to-100%"></div>
          </div>
        ) : (
          <div className="absolute top-0 left-0 w-full h-full">
            <div>
              <img src={generateCloudinaryImageUrl(stashlink.user.profilePhoto ?? "", dynamicImageSize.width, dynamicImageSize.height)} />
              <div className="absolute bottom-0 left-0 right-0 h-2/3 from-50% bg-gradient-to-t from-soft-black to-transparent pointer-events-none" />
            </div>
          </div>
        ))}

      {showParentVideo &&
        (stashlink.parentStashlink?.thankYouMessageVideo && showParentVideo ? (
          <div className="absolute top-0 left-0 w-full h-full">
            {isColinkVideoLoading && (
              <div className="absolute inset-0 flex items-center justify-center z-10">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            )}
            <video
              muted={muted}
              ref={parentVideoRef}
              poster={parentVideoThumbnail}
              src={generateCloudinaryVideoUrl(parentVideoUrl, "750", "930")}
              className={`object-cover w-full h-full ${isColinkVideoLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
              playsInline
              autoPlay
              loop
              onLoadedData={() => setIsColinkVideoLoading(false)}
            />
            <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-soft-black/0 from-30% via-soft-black/0 via-90% to-soft-black/60 to-100%"></div>
          </div>
        ) : (
          <div className="absolute top-0 left-0 w-full h-full">
            <div>
              <img
                src={generateCloudinaryImageUrl(stashlink.parentStashlink?.user.profilePhoto || "", "750", "930")}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 h-2/3 from-50% bg-gradient-to-t from-soft-black to-transparent pointer-events-none" />
              <div className="absolute bottom-20 w-full h-c-70 top-0 bg-gradient-to-t from-black from-5% via-black/70 via-20% to-black/1 to-90%">
                <p className="w-full absolute left-0 p-5 text-center font-secondary text-base leading-tight-19.2 text-base-white">
                  {stashlink.parentStashlink?.thankYouMessage}
                </p>
              </div>
            </div>
          </div>
        ))}

      <div className="w-screen absolute bottom-0">
        <div className="fixed bottom-0 w-full flex flex-col z-10 items-center justify-center bg-gradient-to-t from-soft-black from-10% via-soft-black/70 via-90% to-soft-black/1 to-100% pt-8">
          {/* This code is to just handle the single stashlink we need to add additional logic for co-link and videos */}
          {!showParentVideo && stashlink.thankYouMessageVideo ? (
            ""
          ) : (
            <div className="">
              <p className="w-full p-5 text-center font-secondary text-base leading-tight-19.2 text-base-white">
                {stashlink.thankYouMessage}
              </p>
            </div>
          )}
          {onboardingFeatureFlag && (
            <div className="w-full flex gap-3">
              <div className="w-full px-12">
                {colinkExistData || stashlink.user.id === userId ? (
                  <div className="w-full mt-2 p-4">
                    <button
                      onClick={() => mixpanel.track("team-up-button-clicked")}
                      className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-740 rounded-lg text-grey bg-grey-bg border border-grey flex align-center justify-center">
                      Teamed Up{" "}
                      <span className="ml-1">
                        <img src={FollowingCheck} alt="Following Check"></img>
                      </span>
                    </button>
                  </div>
                ) : (
                  <Button isDisabled={false} title="Team up" onClick={navigateToColink} />
                )}
              </div>
            </div>
          )}
          <div>
            <button
              onClick={() => {
                mixpanel.track("share-button-clicked", {
                  campaignName: campaign?.title,
                  charityName: campaign?.charity?.name,
                  stashlinkId: stashlink?.id
                });
                setIsShareModalOpen(true);
              }}
              className="w-full p-3 min-w-[283px] mb-1 bg-primary-l1 rounded-lg h-11 border-2 border-black tracking-wide text-black text-center text-base font-primary font-wght-740 leading-tight-16.8 shadow-[0px_4px_0px_#6C801F]">
              Share
            </button>
          </div>
          <p className="mt-2 font-primary font-medium text-neutral-50 text-sm mb-14px leading-tight-16.8 ">
            with{" "}
            <span className="font-primary font-bold tracking-wide">
              {showParentVideo ? stashlink.parentStashlink?.user.fullName : stashlink.user.fullName}
            </span>
          </p>
          <div
            className={clsx([
              "bg-black/70 grid gap-4 p-4 py-3 w-full backdrop-blur-8",
              campaign.callToActionUrl?.length ? "grid-cols-2 w-full h-[72px] px-[1rem] py-[0.75rem]" : "grid-cols-1"
            ])}>
            {campaign.callToActionUrl?.length ? (
              <button
                onClick={() => {
                  mixpanel.track("take-action-clicked", {
                    location: "thank-you-page",
                    campaignName: campaign?.title,
                    charityName: campaign?.charity?.name
                  });
                  window.open(formatUrlWithProtocol(campaign?.callToActionUrl), "_blank");
                }}
                className="flex items-center gap-2 w-full bg-grey/20 rounded-lg h-12 border border-[#90a8c1]/70 tracking-0.56 text-base-white justify-center text-center text-sm font-primary font-bold leading-tight-16.8 whitespace-nowrap">
                <img
                  src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "68", "68")}
                  alt="Logo"
                  className="w-[34px] h-[34px] rounded-[999px] pure-black border-[1px] border-solid border-[#90A8C1]/15"
                />
                Take action
              </button>
            ) : null}
            <>
              {!onboardingFeatureFlag ? (
                <>
                  {gamePlayCount > 1 ? (
                    <>
                      {isUserJoinedWaitlist ? (
                        <button className="w-full h-12 px-2 py-2 text-sm font-primary font-bold rounded-lg text-grey bg-grey/20 border border-grey/70 flex align-center justify-center tracking-wide leading-tight-16.8 flex justify-center items-center">
                          On waitlist
                          <span className="ml-1 w-6 h-6">
                            <img src={FollowingCheck} alt="On waitlist check"></img>
                          </span>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            mixpanel.track("join-waitlist-clicked", {
                              campaignName: campaign?.title,
                              charityName: campaign?.charity?.name
                            });
                            setWaitlistModalOpen(true);
                          }}
                          className="w-full p-3 bg-grey/20 rounded-lg h-12 border border-[#90a8c1]/70 tracking-0.56 text-base-white text-center text-sm font-primary font-bold leading-tight-16.8">
                          Join waitlist
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={navigateToStashAgain}
                      className="w-full p-3 bg-grey/20 rounded-lg h-12 border border-[#90a8c1]/70 tracking-0.56 text-base-white text-center text-sm font-primary font-bold leading-tight-16.8">
                      Play again
                    </button>
                  )}
                </>
              ) : (
                <button
                  onClick={navigateToStashAgain}
                  className="w-full p-3 bg-grey/20 rounded-lg h-11 border border-[#90a8c1]/70 tracking-0.56 text-base-white text-center text-sm font-primary font-bold leading-tight-16.8">
                  Play again
                </button>
              )}
            </>
          </div>
        </div>
      </div>
      {isShareModalOpen && (
        <ShareComponent
          stashUser={stashlink.parentStashlink?.user || userData}
          coLinkUser={stashlink.parentStashlink?.user ? userData : undefined}
          charityName={campaign.charity?.name}
          charityLogo={campaign.charity?.imageUrl}
          currentAmount={campaign.currentAmount + 1}
          goalAmount={campaign.goalAmount}
          shareUrl={shareUrl}
          isOpen={isShareModalOpen}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
      <WaitlistModal stashUser={stashlink.parentStashlink?.user || userData} isOpen={isWaitlistModalOpen} onClose={closeWaitListModal} />
    </div>
  );
};

export default ThankYou;
